import React from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { FormControlLabel, Switch } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DeliveryTimeSlot } from "@types";
import { ORDER_STATUS } from "utils/constants";

interface TimeSlotItemProps {
  selected: boolean;
  onSelectTimeSlot: (event: React.MouseEvent<HTMLDivElement>) => void;
  label: string;
}

interface TimeSlotSelectorProps {
  selectedTimeSlot: string | null;
  handleSelectTimeSlot: (timeSlot: string) => void;
  noSuitableTimeSlot: boolean;
  toggleNoSuitableTimeSlot: VoidFunction;
  availableTimeSlotsForDate: [DeliveryTimeSlot];
  loading: boolean;
  handleSubmit: () => void;
  confirmedDateSameAsSelected: boolean;
  status: string;
  isNoSuitableTimeslotAvailable: boolean;
}

const TimeSlotItem = ({
  selected,
  onSelectTimeSlot,
  label,
}: TimeSlotItemProps) => {
  return (
    <div
      className={classNames("time-slot-item", {
        "time-slot-item--selected": selected,
      })}
      onClick={onSelectTimeSlot}
    >
      {label}
    </div>
  );
};

const TimeSlotSelector = ({
  selectedTimeSlot,
  handleSelectTimeSlot,
  noSuitableTimeSlot,
  toggleNoSuitableTimeSlot,
  availableTimeSlotsForDate,
  loading,
  handleSubmit,
  confirmedDateSameAsSelected,
  status,
  isNoSuitableTimeslotAvailable = true,
}: TimeSlotSelectorProps) => {
  const hasTimeSlotAndSuitable = React.useMemo(() => {
    const hasAvailableTimeSlotsForDate = availableTimeSlotsForDate.length >= 1;

    return hasAvailableTimeSlotsForDate && !noSuitableTimeSlot;
  }, [availableTimeSlotsForDate, noSuitableTimeSlot]);

  const isLoadingButtonDisabled = React.useMemo<boolean>(() => {
    if (status == ORDER_STATUS.WAITING_FOR_CUSTOMER && noSuitableTimeSlot) {
      return false;
    }

    return (
      (!selectedTimeSlot &&
        !noSuitableTimeSlot &&
        status != ORDER_STATUS.PENDING) ||
      confirmedDateSameAsSelected ||
      (status == ORDER_STATUS.PENDING && !selectedTimeSlot)
    );
  }, [
    selectedTimeSlot,
    noSuitableTimeSlot,
    confirmedDateSameAsSelected,
    status,
  ]);

  return (
    <div className="time-slot-selector">
      {hasTimeSlotAndSuitable && (
        <div className="time-slot-selector__title">Choisissez un créneau:</div>
      )}
      <div className="time-slot-selector__time-slots">
        {!noSuitableTimeSlot &&
          availableTimeSlotsForDate.map((timeSlot) => {
            const key = timeSlot.key;
            const from = dayjs(timeSlot.date + "T" + timeSlot.hour_from).format(
              "HH:mm"
            );
            const to = dayjs(timeSlot.date + "T" + timeSlot.hour_to).format(
              "HH:mm"
            );

            return (
              <TimeSlotItem
                key={key}
                selected={key === selectedTimeSlot}
                onSelectTimeSlot={() => handleSelectTimeSlot(key)}
                label={`Entre ${from} et ${to}`}
              />
            );
          })}
      </div>
      <div className="time-slot-selector__submit">
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          variant="contained"
          size="large"
          disabled={isLoadingButtonDisabled}
        >
          Valider
        </LoadingButton>
      </div>
      {isNoSuitableTimeslotAvailable && (
        <div className="time-slot-selector__no-suitable-slots">
          <FormControlLabel
            control={
              <Switch
                checked={noSuitableTimeSlot}
                onChange={toggleNoSuitableTimeSlot}
              />
            }
            label="Merci de me contacter car je ne trouve pas de créneau disponible ou qui me convient"
          />
        </div>
      )}
    </div>
  );
};

export default TimeSlotSelector;
